import Resource from '../../../api/resource';
import request from "../../../utils/request";

class Roles extends Resource {
    constructor() {
        super('roles');
    }

    userRoleAndPermissions(user_id) {
        return request({
            url: `/roles/user/${user_id}`,
            method: 'post',
        })
    }

    updatePermission(id , resource) {
        return request({
            url: `/roles/permission/update/${id}`,
            method: 'put',
            data: resource,
        })
    }

    updateUserRoleAndPermissions(resource, user_id) {
        return request({
            url: `/roles/user/${user_id}`,
            method: 'put',
            data: resource,
        })
    }

    export(id) {
        return request({
            url: `/roles/export-roles${id ? `/${id}` : ''}`,
            method: 'post',
            responseType: 'blob',
        })
    }

    exportUsersWithRoles(id) {
        return request({
            url: `/roles/export_users_with_roles`,
            method: 'post',
            responseType: 'blob',
        })
    }
}

export {Roles as default};
