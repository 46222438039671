import Resource from '../../resource'
import request from '../../../utils/request'

class SchoolsList extends Resource {
  constructor() {
    super('schools-list')
  }

  list(query) {
    return request({
      url: `/${this.uri}/schools`,
      method: 'get',
      params: query,
    })
  }

  put(id, query) {
    return request({
      url: `/${this.uri}${id ? '/' + id : ''}`,
      method: 'put',
      data: query,
    })
  }

  delete(id) {
    return request({
      url: `/${this.uri}/${id}`,
      method: 'delete',
    }) 
  }

  export(payload){
    return request({
      url: `/${this.uri}/export`,
      method: 'post',
      responseType: "blob",
      data:payload,
    });
  }
 
}

export { SchoolsList as default }
