import UniversitiesList from '@/api/administration/universities_list/universities_list'
import fileDownload from 'js-file-download'

const universities_list = new UniversitiesList()

const UniversitiesListModule = {
  namespaced: true,
  state: {
    item: null,
    items: [],
    total: 0,
    lookups: null,
    load: false,
  },
  getters: {
    items(state) {
      return state.items
    },
    item(state) {
      return state.item
    },
    load(state) {
      return state.load
    },
    total(state) {
      return state.total
    },
    status(state) {
      return state.item.removed
    },
    lookups(state) {
      return state.lookups
    },
  },
  mutations: {
    SET_ITEMS: (state, items) => {
      state.items = items
    },
    SET_TOTAL_ITEMS: (state, total) => {
      state.total = total
    },
    SET_ITEMS_LOAD: (state, load) => {
      state.load = load
    },
    SET_ITEM: (state, item) => {
      state.item = item
    },
    SET_LOOKUPS(state, lookups) {
      state.lookups = lookups
    },
  },
  actions: {
    list({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_ITEMS_LOAD', true)
        universities_list.list(payload.query).then(response => {
          commit('SET_ITEMS', response.data)
          commit('SET_TOTAL_ITEMS', response.meta.count)
          commit('SET_ITEMS_LOAD', false)
          commit('SET_LOOKUPS', response.meta.lookup)
          resolve()
        })
      })
    },
 
    put({ commit }, payload) {
      console.log(payload.id,payload.query)
      commit('app/UPDATE_LOAD', true, { root: true })
      return new Promise((resolve, reject) => {
        universities_list.put(payload.id,payload.query)
          .then(response => {
            commit('app/UPDATE_LOAD', false, { root: true })
            resolve(response)
          }).catch(error => {
            commit('app/UPDATE_LOAD', false, { root: true })
            reject(error && error.response && error.response.data)
          })
      })
    },

    delete({ commit }, id) {
      return new Promise((resolve, reject) => {
        universities_list.delete(id).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error && error.response && error.response.data)
        })
      })
    },
  
       export({commit},payload){
        console.log(payload)
         return new Promise((resolve, reject) => {
           commit('SET_ITEMS_LOAD', true);
           universities_list
             .export(payload)
             .then(response => {
               fileDownload(response,'universities_list.xlsx');
               commit('SET_ITEMS_LOAD', false);
               resolve(response);
             })
             .catch(error => {
               reject(error);
             });
         });
       }
     },

}

export default UniversitiesListModule
